export default {
    brackets: {
        AL: {
            single: [
                { rate: 2.0, max: 500, plus: 0.0 },
                { rate: 4.0, max: 3000, plus: 10.0 },
                { rate: 5.0, max: 999999999, plus: 110.0 },
            ],
            married: [
                { rate: 2.0, max: 1000, plus: 0.0 },
                { rate: 4.0, max: 6000, plus: 20.0 },
                { rate: 5.0, max: 999999999, plus: 220.0 },
            ],
        },
        AK: {
            single: [{ rate: 0.0, max: 0, plus: 0.0 }],
            married: [{ rate: 0.0, max: 0, plus: 0.0 }],
        },
        AZ: {
            single: [{ rate: 2.5, max: 999999999, plus: 0.0 }],
            married: [{ rate: 2.5, max: 999999999, plus: 0.0 }],
        },
        AR: {
            single: [
                { rate: 0.0, max: 5500, plus: 0.0 },
                { rate: 2.0, max: 10900, plus: 0.0 },
                { rate: 3.0, max: 15600, plus: 108.0 },
                { rate: 3.4, max: 25700, plus: 249.0 },
                { rate: 3.9, max: 999999999, plus: 592.40 },
            ],
            married: [
                { rate: 0.0, max: 11000, plus: 0.0 },
                { rate: 2.0, max: 21800, plus: 0.0 },
                { rate: 3.0, max: 31200, plus: 216.0 },
                { rate: 3.4, max: 51400, plus: 498.0 },
                { rate: 3.9, max: 999999999, plus: 1184.80 },
            ],
        },
        CA: {
            single: [
                { rate: 1.0, max: 10756, plus: 0.0 },
                { rate: 2.0, max: 25499, plus: 107.56 },
                { rate: 4.0, max: 40245, plus: 402.42 },
                { rate: 6.0, max: 55866, plus: 992.26 },
                { rate: 8.0, max: 70606, plus: 1929.52 },
                { rate: 9.3, max: 360659, plus: 3108.72 },
                { rate: 10.3, max: 432787, plus: 30083.65 },
                { rate: 11.3, max: 721314, plus: 37512.83 },
                { rate: 12.3, max: 999999999, plus: 70116.38 },
            ],
            married: [
                { rate: 1.0, max: 21512, plus: 0.0 },
                { rate: 2.0, max: 50998, plus: 215.12 },
                { rate: 4.0, max: 80490, plus: 804.84 },
                { rate: 6.0, max: 111732, plus: 1984.52 },
                { rate: 8.0, max: 141212, plus: 3859.04 },
                { rate: 9.3, max: 721318, plus: 6217.44 },
                { rate: 10.3, max: 865574, plus: 60167.3 },
                { rate: 11.3, max: 1442628, plus: 75025.67 },
                { rate: 12.3, max: 999999999, plus: 140232.77 },
            ],
        },
        CO: {
            single: [{ rate: 4.4, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.4, max: 999999999, plus: 0.0 }],
        },
        CT: {
            single: [
                { rate: 2.0, max: 10000, plus: 0.0 },
                { rate: 4.5, max: 50000, plus: 200.0 },
                { rate: 5.5, max: 100000, plus: 2000.0 },
                { rate: 6.0, max: 200000, plus: 4750.0 },
                { rate: 6.5, max: 250000, plus: 10750.0 },
                { rate: 6.9, max: 500000, plus: 14000.0 },
                { rate: 6.99, max: 999999999, plus: 31250.0 },
            ],
            married: [
                { rate: 2.0, max: 20000, plus: 0.0 },
                { rate: 4.5, max: 100000, plus: 400.0 },
                { rate: 5.5, max: 200000, plus: 4000.0 },
                { rate: 6.0, max: 400000, plus: 9500.0 },
                { rate: 6.5, max: 500000, plus: 21500.0 },
                { rate: 6.9, max: 1000000, plus: 28000.0 },
                { rate: 6.99, max: 999999999, plus: 62500.0 },
            ],
        },
        DE: {
            single: [
                { rate: 0.0, max: 2000, plus: 0.0 },
                { rate: 2.2, max: 5000, plus: 0.0 },
                { rate: 3.9, max: 10000, plus: 66.0 },
                { rate: 4.8, max: 20000, plus: 261.0 },
                { rate: 5.2, max: 25000, plus: 741.0 },
                { rate: 5.55, max: 60000, plus: 1001.0 },
                { rate: 6.6, max: 999999999, plus: 2943.5 },
            ],
            married: [
                { rate: 0.0, max: 2000, plus: 0.0 },
                { rate: 2.2, max: 5000, plus: 0.0 },
                { rate: 3.9, max: 10000, plus: 66.0 },
                { rate: 4.8, max: 20000, plus: 261.0 },
                { rate: 5.2, max: 25000, plus: 741.0 },
                { rate: 5.55, max: 60000, plus: 1001.0 },
                { rate: 6.6, max: 999999999, plus: 2943.5 },
            ],
        },
        DC: {
            single: [
                { rate: 4.0, max: 10000, plus: 0.0 },
                { rate: 6.0, max: 40000, plus: 400.0 },
                { rate: 6.5, max: 60000, plus: 2200.0 },
                { rate: 8.5, max: 250000, plus: 3500.0 },
                { rate: 9.25, max: 500000, plus: 19650.0 },
                { rate: 9.75, max: 1000000, plus: 42775.0 },
                { rate: 10.75, max: 999999999, plus: 91525.0 },
            ],
            married: [
                { rate: 4.0, max: 10000, plus: 0.0 },
                { rate: 6.0, max: 40000, plus: 400.0 },
                { rate: 6.5, max: 60000, plus: 2200.0 },
                { rate: 8.5, max: 250000, plus: 3500.0 },
                { rate: 9.25, max: 500000, plus: 19650.0 },
                { rate: 9.75, max: 1000000, plus: 42775.0 },
                { rate: 10.75, max: 999999999, plus: 91525.0 },
            ],
        },
        FL: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        GA: {
            single: [{ rate: 5.39, max: 999999999, plus: 0.0 }],
            married: [{ rate: 5.39, max: 999999999, plus: 0.0 }],
        },
        HI: {
            single: [
                { rate: 1.4, max: 2400, plus: 0.0 },
                { rate: 3.2, max: 4800, plus: 33.6 },
                { rate: 5.5, max: 9600, plus: 110.4 },
                { rate: 6.4, max: 14400, plus: 374.4 },
                { rate: 6.8, max: 19200, plus: 681.6 },
                { rate: 7.2, max: 24000, plus: 1008.0 },
                { rate: 7.6, max: 36000, plus: 1353.6 },
                { rate: 7.9, max: 999999999, plus: 2265.6 },
            ],
            married: [
                { rate: 1.4, max: 4800, plus: 0.0 },
                { rate: 3.2, max: 9600, plus: 67.2 },
                { rate: 5.5, max: 19200, plus: 220.8 },
                { rate: 6.4, max: 28800, plus: 748.8 },
                { rate: 6.8, max: 38400, plus: 1363.2 },
                { rate: 7.2, max: 48000, plus: 2016.0 },
                { rate: 7.6, max: 72000, plus: 2707.2 },
                { rate: 7.9, max: 999999999, plus: 4531.2 },
            ],
        },
        ID: {
            single: [{ rate: 5.8, max: 999999999, plus: 0.0 }],
            married: [{ rate: 5.8, max: 999999999, plus: 0.0 }],
        },
        IL: {
            single: [{ rate: 4.95, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.95, max: 999999999, plus: 0.0 }],
        },
        IN: {
            single: [{ rate: 3.05, max: 999999999, plus: 0.0 }],
            married: [{ rate: 3.05, max: 999999999, plus: 0.0 }],
        },
        IA: {
            single: [{ rate: 3.8, max: 999999999, plus: 0.0 }],
            married: [{ rate: 3.8, max: 999999999, plus: 0.0 }],
        },
        KS: {
            single: [{ rate: 5.15, max: 999999999, plus: 0.0 }],
            married: [{ rate: 5.15, max: 999999999, plus: 0.0 }],
        },
        KY: {
            single: [{ rate: 4.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.0, max: 999999999, plus: 0.0 }],
        },
        LA: {
            single: [{ rate: 3.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 3.0, max: 999999999, plus: 0.0 }],
        },
        ME: {
            single: [
                { rate: 5.8, max: 26800, plus: 0.0 },
                { rate: 6.75, max: 63450, plus: 1554.4 },
                { rate: 7.15, max: 999999999, plus: 4028.28 },
            ],
            married: [
                { rate: 5.8, max: 53600, plus: 0.0 },
                { rate: 6.75, max: 126900, plus: 3108.8 },
                { rate: 7.15, max: 999999999, plus: 8056.55 },
            ],
        },
        MD: {
            single: [
                { rate: 2.0, max: 1000, plus: 0.0 },
                { rate: 3.0, max: 2000, plus: 20.0 },
                { rate: 4.0, max: 3000, plus: 50.0 },
                { rate: 4.75, max: 100000, plus: 90.0 },
                { rate: 5.0, max: 125000, plus: 4697.5 },
                { rate: 5.25, max: 150000, plus: 5947.5 },
                { rate: 5.5, max: 250000, plus: 7260.0 },
                { rate: 5.75, max: 999999999, plus: 12760.0 },
            ],
            married: [
                { rate: 2.0, max: 1000, plus: 0.0 },
                { rate: 3.0, max: 2000, plus: 20.0 },
                { rate: 4.0, max: 3000, plus: 50.0 },
                { rate: 4.75, max: 150000, plus: 90.0 },
                { rate: 5.0, max: 175000, plus: 7072.5 },
                { rate: 5.25, max: 225000, plus: 8322.5 },
                { rate: 5.5, max: 300000, plus: 10947.5 },
                { rate: 5.75, max: 999999999, plus: 15072.5 },
            ],
        },
        MA: {
            single: [
                { rate: 5.0, max: 1000000, plus: 0.0 },
                { rate: 9.0, max: 999999999, plus: 50000.0 },
            ],
            married: [
                { rate: 5.0, max: 1000000, plus: 0.0 },
                { rate: 9.0, max: 999999999, plus: 50000.0 },
            ],
        },
        MI: {
            single: [{ rate: 4.25, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.25, max: 999999999, plus: 0.0 }],
        },
        MN: {
            single: [
                { rate: 5.35, max: 32570, plus: 0.0 },
                { rate: 6.8, max: 106990, plus: 1742.5 },
                { rate: 7.85, max: 198630, plus: 6803.06 },
                { rate: 9.85, max: 999999999, plus: 13996.8 },
            ],
            married: [
                { rate: 5.35, max: 47620, plus: 0.0 },
                { rate: 6.8, max: 189180, plus: 2547.67 },
                { rate: 7.85, max: 330410, plus: 12173.75 },
                { rate: 9.85, max: 999999999, plus: 23260.31 },
            ],
        },
        MS: {
            single: [
                { rate: 0.0, max: 10000, plus: 0.0 },
                { rate: 4.7, max: 999999999, plus: 0.0 },
            ],
            married: [
                { rate: 0.0, max: 20000, plus: 0.0 },
                { rate: 4.7, max: 999999999, plus: 0.0 },
            ],
        },
        MO: {
            single: [
                { rate: 0.0, max: 1273, plus: 0.0 },
                { rate: 2.0, max: 2546, plus: 0.0 },
                { rate: 2.5, max: 3819, plus: 25.46 },
                { rate: 3.0, max: 5092, plus: 57.29 },
                { rate: 3.5, max: 6365, plus: 95.48 },
                { rate: 4.0, max: 7638, plus: 140.03 },
                { rate: 4.5, max: 8911, plus: 190.95 },
                { rate: 4.8, max: 999999999, plus: 248.24 },
            ],
            married: [
                { rate: 0.0, max: 1273, plus: 0.0 },
                { rate: 2.0, max: 2546, plus: 0.0 },
                { rate: 2.5, max: 3819, plus: 25.46 },
                { rate: 3.0, max: 5092, plus: 57.29 },
                { rate: 3.5, max: 6365, plus: 95.475 },
                { rate: 4.0, max: 7638, plus: 140.03 },
                { rate: 4.5, max: 8911, plus: 190.95 },
                { rate: 4.8, max: 999999999, plus: 248.24 },
            ],
        },
        MT: {
            single: [
                { rate: 4.7, max: 21100, plus: 0.0 },
                { rate: 5.9, max: 999999999, plus: 991.7 },
            ],
            married: [
                { rate: 4.7, max: 42200, plus: 0.0 },
                { rate: 5.9, max: 999999999, plus: 1983.4 },
            ],
        },
        NE: {
            single: [
                { rate: 0.0, max: 3310, plus: 0.0 },
                { rate: 2.26, max: 6480, plus: 0.0 },
                { rate: 3.22, max: 21050, plus: 71.64 },
                { rate: 4.91, max: 30510, plus: 540.8 },
                { rate: 5.07, max: 38740, plus: 1005.28 },
                { rate: 5.23, max: 72750, plus: 1422.54 },
                { rate: 5.37, max: 999999999, plus: 3201.27 },
            ],
            married: [
                { rate: 0.0, max: 7910, plus: 0.0 },
                { rate: 2.26, max: 12560, plus: 0.0 },
                { rate: 3.22, max: 31270, plus: 105.09 },
                { rate: 4.91, max: 48650, plus: 707.55 },
                { rate: 5.07, max: 60360, plus: 1560.91 },
                { rate: 5.23, max: 80040, plus: 2154.61 },
                { rate: 5.37, max: 999999999, plus: 3183.87 },
            ],
        },
        NV: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        NH: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        NJ: {
            single: [
                { rate: 1.4, max: 20000, plus: 0.0 },
                { rate: 1.75, max: 35000, plus: 280.0 },
                { rate: 3.50, max: 40000, plus: 542.5 },
                { rate: 5.525, max: 75000, plus: 717.5 },
                { rate: 6.37, max: 500000, plus: 2651.25 },
                { rate: 8.97, max: 1000000, plus: 29723.75 },
                { rate: 10.75, max: 999999999, plus: 74573.75 },
            ],
            married: [
                { rate: 1.4, max: 20000, plus: 0.0 },
                { rate: 1.75, max: 50000, plus: 280.0 },
                { rate: 2.45, max: 70000, plus: 805.0 },
                { rate: 3.50, max: 80000, plus: 1295.0 },
                { rate: 5.525, max: 150000, plus: 1645.0 },
                { rate: 6.37, max: 500000, plus: 5512.5 },
                { rate: 8.97, max: 1000000, plus: 27807.50 },
                { rate: 10.75, max: 999999999, plus: 72657.5 },
            ],
        },
        NM: {
            single: [
                { rate: 0.0, max: 7300, plus: 0.0 },
                { rate: 1.7, max: 12800, plus: 0.0 },
                { rate: 3.2, max: 18300, plus: 93.5 },
                { rate: 4.7, max: 23300, plus: 269.5 },
                { rate: 4.9, max: 217300, plus: 504.5 },
                { rate: 5.9, max: 999999999, plus: 10010.5 },
            ],
            married: [
                { rate: 0.0, max: 14600, plus: 0.0 },
                { rate: 1.7, max: 22600, plus: 0.0 },
                { rate: 3.2, max: 30600, plus: 136.0 },
                { rate: 4.7, max: 38600, plus: 392.0 },
                { rate: 4.9, max: 329600, plus: 768.0 },
                { rate: 5.9, max: 999999999, plus: 15027.0 },
            ],
        },
        NY: {
            single: [
                { rate: 4.0, max: 8500, plus: 0.0 },
                { rate: 4.5, max: 11700, plus: 340.0 },
                { rate: 5.25, max: 13900, plus: 484.0 },
                { rate: 5.5, max: 80650, plus: 599.5 },
                { rate: 6.0, max: 215400, plus: 4270.75 },
                { rate: 6.85, max: 1077550, plus: 12355.75 },
                { rate: 9.65, max: 5000000, plus: 71413.03 },
                { rate: 10.3, max: 25000000, plus: 449929.45 },
                { rate: 10.9, max: 999999999, plus: 2509929.45 },
            ],
            married: [
                { rate: 4.0, max: 17150, plus: 0.0 },
                { rate: 4.5, max: 23600, plus: 686.0 },
                { rate: 5.25, max: 27900, plus: 976.25 },
                { rate: 5.5, max: 161550, plus: 1202.0 },
                { rate: 6.0, max: 323200, plus: 8552.75 },
                { rate: 6.85, max: 2155350, plus: 18251.75 },
                { rate: 9.65, max: 5000000, plus: 143754.03 },
                { rate: 10.3, max: 25000000, plus: 418262.75 },
                { rate: 10.9, max: 999999999, plus: 2478262.75 },
            ],
        },
        NC: {
            single: [{ rate: 4.25, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.25, max: 999999999, plus: 0.0 }],
        },
        ND: {
            single: [
                { rate: 0.0, max: 52025, plus: 0.0 },
                { rate: 1.95, max: 233275, plus: 0.0 },
                { rate: 2.5, max: 999999999, plus: 3534.38 },
            ],
            married: [
                { rate: 0.0, max: 70900, plus: 0.0 },
                { rate: 1.95, max: 261500, plus: 0.0 },
                { rate: 2.5, max: 999999999, plus: 3716.7 },
            ],
        },
        OH: {
            single: [
                { rate: 0.0, max: 26050, plus: 0.0 },
                { rate: 2.75, max: 100000, plus: 0.0 },
                { rate: 3.5, max: 999999999, plus: 2033.63 },
            ],
            married: [
                { rate: 0.0, max: 26050, plus: 0.0 },
                { rate: 2.75, max: 100000, plus: 0.0 },
                { rate: 3.5, max: 999999999, plus: 2033.63 },
            ],
        },
        OK: {
            single: [
                { rate: 0.0, max: 6350, plus: 0.0 },
                { rate: 0.25, max: 7350, plus: 0.0 },
                { rate: 0.75, max: 8850, plus: 2.5 },
                { rate: 1.75, max: 10100, plus: 13.75 },
                { rate: 2.75, max: 11250, plus: 35.63 },
                { rate: 3.75, max: 13550, plus: 67.25 },
                { rate: 4.75, max: 999999999, plus: 153.5 },
            ],
            married: [
                { rate: 0.0, max: 12700, plus: 0.0 },
                { rate: 0.25, max: 14700, plus: 0.0 },
                { rate: 0.75, max: 17700, plus: 5.0 },
                { rate: 1.75, max: 20200, plus: 27.5 },
                { rate: 2.75, max: 22500, plus: 71.25 },
                { rate: 3.75, max: 27100, plus: 134.5 },
                { rate: 4.75, max: 999999999, plus: 307.0 },
            ],
        },
        OR: {
            single: [
                { rate: 4.75, max: 4300, plus: 0.0 },
                { rate: 6.75, max: 10750, plus: 204.25 },
                { rate: 8.75, max: 125000, plus: 639.63 },
                { rate: 9.9, max: 999999999, plus: 10636.5 },
            ],
            married: [
                { rate: 4.75, max: 8600, plus: 0.0 },
                { rate: 6.75, max: 21500, plus: 408.5 },
                { rate: 8.75, max: 250000, plus: 1279.25 },
                { rate: 9.9, max: 999999999, plus: 21273.0 },
            ],
        },
        PA: {
            single: [{ rate: 3.07, max: 999999999, plus: 0.0 }],
            married: [{ rate: 3.07, max: 999999999, plus: 0.0 }],
        },
        PR: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        RI: {
            single: [
                { rate: 3.75, max: 77450, plus: 0.0 },
                { rate: 4.75, max: 176050, plus: 2904.38 },
                { rate: 5.99, max: 999999999, plus: 7587.88 },
            ],
            married: [
                { rate: 3.75, max: 77450, plus: 0.0 },
                { rate: 4.75, max: 176050, plus: 2904.38 },
                { rate: 5.99, max: 999999999, plus: 7587.88 },
            ],
        },
        SC: {
            single: [
                { rate: 0.0, max: 3560, plus: 0.0 },
                { rate: 3.0, max: 17830, plus: 0.0 },
                { rate: 6.0, max: 999999999, plus: 428.1 },
            ],
            married: [
                { rate: 0.0, max: 3560, plus: 0.0 },
                { rate: 3.0, max: 17830, plus: 0.0 },
                { rate: 6.0, max: 999999999, plus: 428.1 },
            ],
        },
        SD: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        TN: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        TX: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        UT: {
            single: [{ rate: 4.55, max: 999999999, plus: 0.0 }],
            married: [{ rate: 4.55, max: 999999999, plus: 0.0 }],
        },
        VT: {
            single: [
                { rate: 3.35, max: 47900, plus: 0.0 },
                { rate: 6.6, max: 116000, plus: 1604.65 },
                { rate: 7.6, max: 242000, plus: 6099.25 },
                { rate: 8.75, max: 999999999, plus: 15675.25 },
            ],
            married: [
                { rate: 3.35, max: 79950, plus: 0.0 },
                { rate: 6.6, max: 193300, plus: 2678.33 },
                { rate: 7.6, max: 294600, plus: 10159.43 },
                { rate: 8.75, max: 999999999, plus: 17858.23 },
            ],
        },
        VA: {
            single: [
                { rate: 2.0, max: 3000, plus: 0.0 },
                { rate: 3.0, max: 5000, plus: 60.0 },
                { rate: 5.0, max: 17000, plus: 120.0 },
                { rate: 5.75, max: 999999999, plus: 720.0 },
            ],
            married: [
                { rate: 2.0, max: 3000, plus: 0.0 },
                { rate: 3.0, max: 5000, plus: 60.0 },
                { rate: 5.0, max: 17000, plus: 120.0 },
                { rate: 5.75, max: 999999999, plus: 720.0 },
            ],
        },
        WA: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
        WV: {
            single: [
                { rate: 2.22, max: 10000, plus: 0.0 },
                { rate: 2.96, max: 25000, plus: 222.0 },
                { rate: 3.33, max: 40000, plus: 666.0 },
                { rate: 4.44, max: 60000, plus: 1165.5 },
                { rate: 4.82, max: 999999999, plus: 2053.5 },
            ],
            married: [
                { rate: 2.22, max: 10000, plus: 0.0 },
                { rate: 2.96, max: 25000, plus: 222.0 },
                { rate: 3.33, max: 40000, plus: 666.0 },
                { rate: 4.44, max: 60000, plus: 1165.5 },
                { rate: 4.82, max: 999999999, plus: 2053.5 },
            ],
        },
        WI: {
            single: [
                { rate: 3.5, max: 14320, plus: 0.0 },
                { rate: 4.4, max: 28640, plus: 501.2 },
                { rate: 5.3, max: 315310, plus: 1131.28 },
                { rate: 7.65, max: 999999999, plus: 16324.79 },
            ],
            married: [
                { rate: 3.5, max: 19090, plus: 0.0 },
                { rate: 4.4, max: 38190, plus: 668.15 },
                { rate: 5.3, max: 420420, plus: 1508.55 },
                { rate: 7.65, max: 999999999, plus: 21766.74 },
            ],
        },
        WY: {
            single: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
            married: [{ rate: 0.0, max: 999999999, plus: 0.0 }],
        },
    },
    standardDeduction: {
        AL: { single: 2500, married: 7500 },
        AK: { single: 0, married: 0 },
        AZ: { single: 14600, married: 29200 },
        AR: { single: 2410, married: 4820 },
        CA: { single: 5540, married: 11080 },
        CO: { single: 14600, married: 29200 },
        CT: { single: 0, married: 0 },
        DE: { single: 5700, married: 11400 },
        DC: { single: 14600, married: 29200 },
        FL: { single: 0, married: 0 },
        GA: { single: 12000, married: 18500 },
        HI: { single: 4400, married: 8800 },
        ID: { single: 14600, married: 29200 },
        IL: { single: 0, married: 0 },
        IN: { single: 0, married: 0 },
        IA: { single: 0, married: 0 },
        KS: { single: 6150, married: 12300 },
        KY: { single: 3160, married: 6320 },
        LA: { single: 12500, married: 25000 },
        ME: { single: 15000, married: 30000 },
        MD: { single: 2550, married: 5100 },
        MA: { single: 4400, married: 8800 },
        MI: { single: 5400, married: 5400 },
        MN: { single: 14950, married: 29900 },
        MS: { single: 6000, married: 12000 },
        MO: { single: 14600, married: 29200 },
        MT: { single: 5540, married: 11080 },
        NE: { single: 7900, married: 15800 },
        NV: { single: 0, married: 0 },
        NH: { single: 0, married: 0 },
        NJ: { single: 0, married: 0 },
        NM: { single: 14600, married: 29200 },
        NY: { single: 8000, married: 16050 },
        NC: { single: 12750, married: 25500 },
        ND: { single: 14600, married: 29200 },
        OH: { single: 0, married: 0 },
        OK: { single: 6350, married: 12700 },
        OR: { single: 2745, married: 5495 },
        PA: { single: 0, married: 0 },
        PR: { single: 0, married: 0 },
        RI: { single: 10550, married: 21150 },
        SC: { single: 6925, married: 6925 },
        SD: { single: 0, married: 0 },
        TN: { single: 0, married: 0 },
        TX: { single: 0, married: 0 },
        UT: { single: 0, married: 0 },
        VT: { single: 7400, married: 14850 },
        VA: { single: 8000, married: 16000 },
        WA: { single: 0, married: 0 },
        WV: { single: 0, married: 0 },
        WI: { single: 13230, married: 24490 },
        WY: { single: 0, married: 0 },
    },

    disability: {
        CA: {
            name: "SDI",
            max: 999999999, //maximum cap has been removed starting 2024.
            rate: 1.2 / 100,
        },
        HI: {
            name: "STI",
            max: 74984, //Weekly maximum is $7.21.
            rate: 0.5 / 100,
        },
        NJ: {
            name: "SDI",
            max: 165400,
            rate: 0.23 / 100,
        },
        NY: {
            name: "SDI",
            max: 6240,
            rate: 0.5 / 100,
        },
        RI: {
            name: "SDI",
            max: 89200,
            rate: 1.3 / 100,
        },
    },
};
